import React, { useEffect } from "react";
import { graphql, HeadFC } from "gatsby";
import { useLocation } from "@reach/router"
import { initializeAndTrack } from "gatsby-plugin-gdpr-cookies"

const Html2ReactParser = require("html-to-react/lib/parser");

// Components
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import Footer from "../components/footer/footer";
import Navigation from "../components/navigation/navigation";

// CSS
import StylesComponent from "../components/styles/styles-component";
import "../sass/page-specific/policy-styles.scss";
import SEO from "../components/seo/seo";
import ContentNavigation from "../components/content-navigation/content-navigation";
import RelatedPages from "../components/related-pages/related-pages";
import CookiesConsentNotice from "../components/cookies-consent/cookies-consent";

interface pageProps {
   contentfulPolicy: {
      title: string,
      slug: string,
      content: {
         internal: {
            content: string
         }
      },
      metaDescription: {
         internal: {
            content: string
         }
      },
      navigation: {
         internal: {
            content: string
         }
      },
      heroDescription: {
         internal: {
            content: string
         }
      }
   }
}

const PolicyTempate: React.FC<{ data: pageProps }> = ({ data }): JSX.Element => {
   useEffect(() => {
      window.scrollTo(0, 0)
   }, [])
   
   const page_details = data.contentfulPolicy;

   const convertContentHTML = (): React.ReactNode => {
      const content = page_details.content.internal.content;
      const htmlToReactParser = new Html2ReactParser();
      const reactElement = htmlToReactParser.parse(content)

      return reactElement
   }

   let path: string = "";
   if(typeof window !== "undefined") {
      path = window.location.pathname;
   }

   return (
      <React.Fragment>
         <StylesComponent/>
         <Navigation/>
         {
            path !== '/privacy-policy' && path !== '/cookies-policy' ? (
               <CookiesConsentNotice/>
            ) : null
         }
         
         <div className="policy-hero-outer-container">
            <div className="policy-hero-container-overlay">
               <div className="policy-hero-text-container container-width">
                  <h1>{page_details.title}</h1>
                  <p className="secondary-text">{page_details.heroDescription.internal.content}</p>
               </div>
            </div>
         </div>

         <div className="policy-content-outer-container">
            <div className="policy-content-grid-container container-width">
               <div className="main-content-container">
                  <Breadcrumbs location={page_details.slug}/>
                  {convertContentHTML()}
               </div>

               <div className="content-sidebar-container">
                  <div className="sidebar-cta-wrapper">
                     <ContentNavigation navigation={page_details.navigation.internal.content}/>

                     <RelatedPages options={{
                        "Cookies policy": "/cookies-policy",
                        "Privacy policy": "/privacy-policy",
                        "Terms & conditions": "/terms-and-conditions",
                        "Communications opt-out": "/opt-out"
                     }}/>
                  </div>
               </div>
            </div>
         </div>

         <Footer/>
      </React.Fragment>
   )
}

export default PolicyTempate

export const Head: HeadFC<pageProps> = ({ data }) => (
   <SEO
      title={`${data.contentfulPolicy.title} | Prestige VIP`}
      metaDescription={data.contentfulPolicy.metaDescription.internal.content}
      slug={"/" + data.contentfulPolicy.slug}
   />
)

export const pageQuery = graphql`
   query pageBySlug ( $slug: String! ) {
      contentfulPolicy ( slug: { eq: $slug } ) {
         title
         slug
         content {
            internal {
               content
            }
         }
         metaDescription {
            internal {
               content
            }
         }
         heroDescription {
            internal {
               content
            }
         }
         navigation {
            internal {
               content
            }
         }
      }
   }
`