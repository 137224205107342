import React from "react";

// CSS
import "./content-navigation-styles.scss";

const ContentNavigation: React.FC<{ navigation: string }> = ({ navigation }): JSX.Element => {
   const navigationJSON = JSON.parse(navigation);

   const generateNavigationOptions = (): JSX.Element[] => {
      let jsx: JSX.Element[] = [];

      for (const key in navigationJSON) {
         jsx.push(<option value={navigationJSON[key]}>{key}</option>)
      }

      return jsx;
   }

   const handleNavigation = (e: React.ChangeEvent<HTMLSelectElement>): void => {
      const { value } = e.target;
      console.log(value)

      try {
         document.getElementById(value)?.scrollIntoView({ behavior: "smooth" })
      }

      catch {
         window.scrollTo({ top: 400, behavior: "smooth" })
      }
   }

   return (
      <label className="content-navigation-container" htmlFor="content-navigation">
         <h3>Page navigation</h3>

         <select id="content-navigation" value="" onChange={e => handleNavigation(e)}>
            <option value="" selected disabled>Skip to section</option>
            {generateNavigationOptions()}
         </select>
      </label>
   )
}

export default ContentNavigation